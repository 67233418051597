import { Select, message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Service({
  serviceType,
  setserviceType,
  serviceCategory,
  setserviceCategory,
  experience,
  setexperience,
  phone,
  setPhone,
}) {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const displayError = (message) => {
    return messageApi.open({
      type: "warning",
      content: message,
    });
  };
  return (
    <div className="container-web">
      {contextHolder}
      <div className="h-3">
        <span
          class="material-symbols-outlined pointer m-1"
          onClick={() => navigate("/kyc")}
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">Service Details</p>
      </div>
      <div className="w-90 m-auto">
        <div className="form-group mt-1">
          <p className="form-label">Enter Service Name</p>
          <input
            className="text-input"
            value={serviceType}
            onChange={(e) => setserviceType(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Service Category</p>
          <Select
            defaultValue="Cleaning"
            style={{
              width: "98%",
            }}
            onChange={(value) => setserviceCategory(value)}
            options={[
              {
                value: "Cleaning",
                label: "Cleaning",
              },
              {
                value: "Plumping",
                label: "Plumping",
              },
              {
                value: "electrical",
                label: "electrical",
              },
              {
                value: "painting",
                label: "painting",
              },
              {
                value: "General handy man",
                label: "General handy man",
              },
              {
                value: "Electronics repair",
                label: "Electronics repair",
              },
              {
                value: "Moving",
                label: "Moving",
              },
              {
                value: "Funrniture Repairs",
                label: "Funrniture Repairs",
              },
            ]}
          />
  
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Experience</p>
          <input
            className="text-input"
            value={experience}
            onChange={(e) => setexperience(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Work Phone</p>
          <input
            className="text-input"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div
          className="new-vendor-btn"
          onClick={() => {
            if (serviceType === "") {
              displayError("Please enter a service name");
            } else if (serviceCategory === "") {
              displayError("Please select a service category");
            } else if (experience === "") {
              displayError("Please enter your experience (in years)");
            } else if (phone === "") {
              displayError("Please enter your work phone number");
            } else {
              navigate("/upload");

              console.log(serviceCategory, serviceType, phone, experience);
            }
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}
