import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function AllVendors() {
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
      } else {
        navigate("/login");
      }
    });
  }, []);

  return (
    <div className="container-web">
      <div className="h-3">
        <span
          class="material-symbols-outlined pointer m-1"
          onClick={() => navigate("/")}
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">All Vendors</p>
      </div>
      <div className="w-90 m-auto">
        <div className="form-group mt-1">
          <p className="form-label">
            {location.state.vendors.length} registered vendors
          </p>
          {/* start */}
          {location.state.vendors.map((vendor) => {
            return (
              <div
                onClick={() => {
                  navigate("/vendor/detail", { state: { vendor: vendor } });
                }}
                className="w-90 m-auto mt-1 h-4 border pointer align-center pl-1 flex"
              >
                <img src={vendor.pic} className="icon-pic" />
                <div>
                  <p className="ml-1 ">
                    {vendor.firstName} {vendor.secondName}
                  </p>
                  <p className="mt-n1 ml-1 text-grey text-xm">{vendor.phone}</p>
                </div>
              </div>
            );
          })}

          {/* end of vendor card */}
        </div>
        {/* 
        <div
          className="new-vendor-btn "
          onClick={() => navigate("/allvendors")}
        >
          Review
        </div> */}
      </div>
    </div>
  );
}
