import React from "react";
import { useNavigate } from "react-router-dom";
import dp from "../assets/dp.svg";
import email from "../assets/message.png";
import phone from "../assets/phone.png";
import location from "../assets/location.png";
import id from "../assets/idicon.png";

export default function Profile() {
  let navigate = useNavigate();
  return (
    <div className="container-web">
      <div className="profile-header">
        <span
          class="material-symbols-outlined m-1 pointer"
          onClick={() => navigate("/")}
        >
          arrow_back
        </span>
        <p className="profile-name">ChapChap Admin</p>
        <div className="dp-formatter">
          <img src={dp} />
        </div>
      </div>
      <div>
        <div
          onClick={() => {
            // navigate("/edit/vendor/details", { state: { vendor: vendor } });
          }}
          className="w-90 m-auto mt-1 h-4 bg-grey pointer align-center pl-1 flex"
        >
          <img src={email} className="" />
          <div>
            <p className="ml-1 "> Email Address</p>
            <p className="mt-n1 ml-1 text-grey text-xm"> admin@chapchap.com</p>
          </div>
        </div>
        <div
          onClick={() => {
            // navigate("/edit/vendor/details", { state: { vendor: vendor } });
          }}
          className="w-90 m-auto mt-1 h-4 bg-grey pointer align-center pl-1 flex"
        >
          <img src={phone} className="" />
          <div>
            <p className="ml-1 "> Phone Number</p>
            <p className="mt-n1 ml-1 text-grey text-xm"> 070000000</p>
          </div>
        </div>{" "}
        <div
          onClick={() => {
            // navigate("/edit/vendor/details", { state: { vendor: vendor } });
          }}
          className="w-90 m-auto mt-1 h-4 bg-grey pointer align-center pl-1 flex"
        >
          <img src={location} className="" />
          <div>
            <p className="ml-1 "> Area of Operation</p>
            <p className="mt-n1 ml-1 text-grey text-xm"> Nairobi Kenya</p>
          </div>
        </div>{" "}
        <div
          onClick={() => {
            // navigate("/edit/vendor/details", { state: { vendor: vendor } });
          }}
          className="w-90 m-auto mt-1 h-4 bg-grey pointer align-center pl-1 flex"
        >
          <img src={id} className="" />
          <div>
            <p className="ml-1 "> ID Number</p>
            <p className="mt-n1 ml-1 text-grey text-xm"> 000000001</p>
          </div>
        </div>
      </div>
    </div>
  );
}
