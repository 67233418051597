import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Spin, message } from "antd";

export default function Login() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const login_user = () => {
    setLoading(true);
    const auth = getAuth();
    setTimeout(() => {
      signInWithEmailAndPassword(auth, email, Password)
        .then((userCredential) => {
          // Signed in
          setLoading(false);

          const user = userCredential.user;
          console.log(user);
          navigate("/");
          // ...
        })
        .catch((error) => {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: error.message,
          });

          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(error);
        });
    }, 2000);
  };

  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  return (
    <div className="container-web-login">
      {contextHolder}
      <div className="">
        <div className="flex ml-2">
          <p className="green font-bold font-md">Chap</p>
          <p className="font-bold font-md">Chap</p>
        </div>
      </div>
      <div className="w-90 m-auto">
        <div className="form-group mt-1">
          <p className="form-label">Email</p>
          <input
            className="text-input"
            value={email}
            placeholder="email@chapchap.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Password</p>
          <input
            className="text-input"
            value={Password}
            placeholder="****"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div
          className="new-vendor-btn "
          onClick={() => {
            login_user();
          }}
        >
          {loading && <Spin />}

          {loading ? <p className="ml-1">Signing in...</p> : <p>Login</p>}
        </div>
      </div>
    </div>
  );
}
