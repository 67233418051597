import "./App.css";

import Home from "./Screens/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import KYC from "./Screens/KYC";
import Service from "./Screens/Service";
import Upload from "./Screens/Upload";
import UploadPicture from "./Screens/UploadPicture";
import AllVendors from "./Screens/AllVendors";
import VendorDetail from "./Screens/VendorDetail";
import EditPersonalDetails from "./Screens/EditPersonalDetails";
import Logout from "./Screens/LogoutScreen";
import Login from "./Screens/Login";
import UploadDP from "./Screens/Uploaddp";
import { useState } from "react";
import { db, storage } from "./Firebase";
import { Modal, Progress, Spin, message } from "antd";
import { collection, addDoc } from "firebase/firestore";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import EditServiceDetails from "./Screens/EditServiceDetails";
import EditDocuments from "./Screens/EditDocuments";
import Profile from "./Screens/Profile";
function App() {
  const [firstName, setfirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [idNo, setIdNo] = useState("");
  const [birthDate, setbirthDate] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [levelOfEducation, setlevelOfEducation] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [serviceCategory, setserviceCategory] = useState("");
  const [experience, setexperience] = useState("");
  const [phone, setPhone] = useState("");
  const [idFront, setidFront] = useState("");
  const [idBack, setidBack] = useState("");
  const [police, setPolice] = useState("");
  const [photo, setPhoto] = useState("");
  const [percentage, setPercent] = useState(0);

  const handleidFrontUpload = () => {
    if (!idFront) {
      messageApi.open({
        type: "error",
        content:
          "Please select a picture of your front side of your id card to proceed",
      });
    } else if (!idBack) {
      messageApi.open({
        type: "error",
        content:
          "Please select a picture of your back side of your id card to proceed",
      });
    } else if (!photo) {
      messageApi.open({
        type: "error",
        content: "Please select a display photo to proceed",
      });
    } else {
      setIsModalOpen(true);
      const storageRef = ref(storage, `/files/${idFront.name}`);
      const uploadTask = uploadBytesResumable(storageRef, idFront);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url1) => {
            console.log(url1);

            const storageRef2 = ref(storage, `/files/${idBack.name}`);
            const uploadTask2 = uploadBytesResumable(storageRef2, idBack);
            uploadTask2.on(
              "state_changed",
              (snapshot) => {
                const percent = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setPercent(percent);
              },
              (err) => console.log(err),
              () => {
                getDownloadURL(uploadTask2.snapshot.ref).then((url2) => {
                  console.log(url2);

                  const storageRef3 = ref(storage, `/files/${photo.name}`);
                  const uploadTask3 = uploadBytesResumable(storageRef3, photo);
                  uploadTask3.on(
                    "state_changed",
                    (snapshot) => {
                      const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                      );
                      setPercent(percent);
                    },
                    (err) => console.log(err),
                    () => {
                      getDownloadURL(uploadTask3.snapshot.ref).then((url3) => {
                        console.log(url3);
                        const storageRef4 = ref(
                          storage,
                          `/files/${police.name}`
                        );
                        const uploadTask4 = uploadBytesResumable(
                          storageRef4,
                          police
                        );

                        uploadTask4.on(
                          "state_changed",
                          (snapshot) => {
                            const percent = Math.round(
                              (snapshot.bytesTransferred /
                                snapshot.totalBytes) *
                                100
                            );
                            setPercent(percent);
                          },
                          (err) => console.log(err),
                          () => {
                            getDownloadURL(uploadTask4.snapshot.ref).then(
                              (url4) => {
                                console.log(url4);
                                const storageRef4 = ref(
                                  storage,
                                  `/files/${police.name}`
                                );
                                const uploadTask4 = uploadBytesResumable(
                                  storageRef4,
                                  police
                                );

                                let VendorDetails = {
                                  firstName,
                                  secondName,
                                  idNo,
                                  birthDate,
                                  homeAddress,
                                  levelOfEducation,
                                  serviceType,
                                  serviceCategory,
                                  experience,
                                  phone,
                                  front: url1,
                                  back: url2,
                                  pic: url3,
                                  police: url4,
                                };

                                console.log(VendorDetails);
                                addDoc(collection(db, "Vendors"), VendorDetails)
                                  .then((docRef) => {
                                    console.log(docRef.id);
                                    setIsModalOpen(false);
                                    setIsModalOpen(false);
                                    messageApi.success(
                                      "Everything went well from our side! ID uploaded successfully"
                                    );
                                    window.location.replace("/");
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    setIsModalOpen(false);
                                    messageApi.error(
                                      "We encountered an error while registering the vendor, try again later"
                                    );
                                    console.error(
                                      "Error adding document: ",
                                      err
                                    );
                                  });
                              }
                            );
                          }
                        );
                      });
                    }
                  );
                });
              }
            );
          });
        }
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="flex">
          <Spin />
          <p className="ml-2 ">Stay put as we verify the credentials</p>
        </div>
        <p className="ml-5 ">{percentage} % Done</p>
      </Modal>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/kyc"
            element={
              <KYC
                firstName={firstName}
                secondName={secondName}
                idNo={idNo}
                birthDate={birthDate}
                homeAddress={homeAddress}
                levelOfEducation={levelOfEducation}
                setfirstName={setfirstName}
                setSecondName={setSecondName}
                setIdNo={setIdNo}
                setbirthDate={setbirthDate}
                setHomeAddress={setHomeAddress}
                setlevelOfEducation={setlevelOfEducation}
              />
            }
          />
          <Route
            exact
            path="/service"
            element={
              <Service
                serviceType={serviceType}
                setserviceType={setserviceType}
                serviceCategory={serviceCategory}
                setserviceCategory={setserviceCategory}
                experience={experience}
                setexperience={setexperience}
                phone={phone}
                setPhone={setPhone}
              />
            }
          />
          <Route
            exact
            path="/upload"
            element={
              <Upload
                idFront={idFront}
                setidFront={setidFront}
                setidBack={setidBack}
                police={police}
                setPolice={setPolice}
                idBack={idBack}
                handleidFrontUpload={handleidFrontUpload}
              />
            }
          />
          <Route
            exact
            path="/uploadpic"
            element={
              <UploadPicture
                photo={photo}
                handleidFrontUpload={handleidFrontUpload}
                setPhoto={setPhoto}
              />
            }
          />
          <Route exact path="/allvendors" element={<AllVendors />} />
          <Route exact path="/vendor/detail" element={<VendorDetail />} />
          <Route exact path="/test" element={<UploadDP />} />
          <Route
            exact
            path="/edit/vendor/details"
            element={<EditPersonalDetails />}
          />
          <Route
            exact
            path="/edit/service/details"
            element={<EditServiceDetails />}
          />
          <Route
            exact
            path="/edit/document/details"
            element={<EditDocuments />}
          />
          <Route
            exact
            path="/profile"
            element={<Profile />}
          />
          <Route exact path="/logout" element={<Logout />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
