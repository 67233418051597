import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../Firebase";

export default function EditPersonalDetails() {
  let navigate = useNavigate();
  let location = useLocation();

  const vendor = location.state.vendor;

  const [homeAddress, setHomeAddress] = useState(vendor.homeAddress);
  const [levelOfEducation, setlevelOfEducation] = useState(
    vendor.levelOfEducation
  );

  const updateData = async () => {
    try {
      const docRef = doc(db, "Vendors", location.vendor.id);
      console.log("id is " + docRef.id);
      await updateDoc(docRef, {
        homeAddress: homeAddress,
        levelOfEducation: levelOfEducation,
      });
    } catch (e) {
      console.log(e);
    }

    // Set the "capital" field of the city 'DC'
    // await updateDoc(docRef, {
    //   capital: true,
    // });
  };
  return (
    <div className="container-web">
      <div className="h-3">
        <span
          class="material-symbols-outlined pointer m-1"
          onClick={() =>
            // navigate("/vendor/detail", { state: { vendor: vendor } })
            navigate("/vendor/detail", { state: { vendor: vendor } })
          }
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">
          {" "}
          {location.state.vendor.firstName} {location.state.vendor.secondName}
        </p>
      </div>
      <div className="w-90 m-auto">
        <div className="form-group mt-1">
          <p className="form-label">Personal Details</p>
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Home Address</p>
          <input
            className="text-input"
            onChange={(e) => setHomeAddress(e.target.value)}
            value={homeAddress}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Level of Education</p>
          <input
            className="text-input"
            onChange={(e) => setlevelOfEducation(e.target.value)}
            value={levelOfEducation}
          />
        </div>
        {/* <div className="spacer"></div> */}
        <p
          className="text-center text-sm"
          style={{ color: "rgba(0, 0, 0, 0.6)" }}
        >
          {" "}
          Personal details such as ID, name, photo and birth date cannot be
          changed. Contact Support
        </p>

        <div className="new-vendor-btn" onClick={updateData}>
          Updated
        </div>
      </div>
    </div>
  );
}
