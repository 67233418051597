import React from "react";
import dp from "../assets/dp.svg";
import girl from "../assets/girl.svg";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <div className="header">
        <div className="flex align-center justify-between w-90 m-auto pt-1">
          <div>
            <div className="flex">
              <p className="green font-bold font-md">Chap</p>
              <p className="font-bold font-md">Chap</p>
            </div>
          </div>
          <div className="">
            <span class="material-symbols-outlined">chat_bubble</span>
            <img src={dp} className="ml-2" />
          </div>
        </div>
        <div className="flex justify-between w-90 m-auto align-center">
          <div>
            <p className="text-md font-bold">2345</p>
            <p className="text-xm mt-n1">Vendors Registered</p>
          </div>
          <div className="circlular-floating-btn">
            <span class="material-symbols-outlined">east</span>
          </div>
        </div>
      </div>
      {/* end of header */}
      <div className="font-bold m-1">Recently Added</div>
      {/* vendor card */}
      <div
        onClick={() => {
          navigate("/vendor/detail");
        }}
        className="w-90 m-auto mt-1 h-4 border align-center pl-1 flex"
      >
        <img src={girl} />
        <div>
          <p className="ml-1 ">Pheneas Munene</p>
          <p className=" ml-1 text-grey text-xm">Pheneas Munene </p>
        </div>
      </div>
      {/* end of vendor card */}
      {/* vendor card */}
      <div
        onClick={() => {
          navigate("/vendor/detail");
        }}
        className="w-90 m-auto mt-1 h-4 border align-center pl-1 flex"
      >
        <img src={girl} />
        <div>
          <p className="ml-1 ">Pheneas Munene</p>
          <p className="mt-n1 ml-1 text-grey text-xm">Pheneas Munene</p>
        </div>
      </div>
      {/* end of vendor card */} {/* vendor card */}
      <div
        onClick={() => {
          navigate("/vendor/detail");
        }}
        className="w-90 m-auto mt-1 h-4 border align-center pl-1 flex"
      >
        <img src={girl} />
        <div>
          <p className="ml-1 ">Pheneas Munene</p>
          <p className="mt-n1 ml-1 text-grey text-xm">Pheneas Munene</p>
        </div>
      </div>
      {/* end of vendor card */} {/* vendor card */}
      <div
        onClick={() => {
          navigate("/vendor/detail");
        }}
        className="w-90 m-auto mt-1 h-4 border align-center pl-1 flex"
      >
        <img src={girl} />
        <div>
          <p className="ml-1 ">Pheneas Munene</p>
          <p className="mt-n1 ml-1 text-grey text-xm">Pheneas Munene</p>
        </div>
      </div>
      {/* end of vendor card */} {/* vendor card */}
      <div
        onClick={() => {
          navigate("/vendor/detail");
        }}
        className="w-90 m-auto mt-1 h-4 border align-center pl-1 flex"
      >
        <img src={girl} />
        <div>
          <p className="ml-1 ">Pheneas Munene</p>
          <p className="mt-n1 ml-1 text-grey text-xm">Pheneas Munene</p>
        </div>
      </div>
      {/* end of vendor card */}
      {/* new vendor btn */}
      <div className="new-vendor-btn" onClick={() => navigate("/kyc")}>
        New Vendor
      </div>
    </div>
  );
}
