import { Select, message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function KYC({
  firstName,
  secondName,
  idNo,
  birthDate,
  homeAddress,
  levelOfEducation,
  setfirstName,
  setSecondName,
  setIdNo,
  setbirthDate,
  setHomeAddress,
  setlevelOfEducation,
}) {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const displayError = (message) => {
    return messageApi.open({
      type: "warning",
      content: message,
    });
  };
  return (
    <div className="container-web">
      {contextHolder}
      <div className="h-3">
        <span
          class="material-symbols-outlined m-1 pointer"
          onClick={() => navigate("/")}
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">Know Your Customer</p>
      </div>
      <div className="w-90 m-auto">
        <div className="form-group mt-1">
          <p className="form-label">First Name</p>
          <input
            className="text-input"
            value={firstName}
            onChange={(e) => setfirstName(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Second Name</p>
          <input
            className="text-input"
            value={secondName}
            onChange={(e) => setSecondName(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">ID Number</p>
          <input
            className="text-input"
            value={idNo}
            onChange={(e) => setIdNo(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Birth Date</p>
          <input
            className="text-input"
            value={birthDate}
            onChange={(e) => setbirthDate(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Home Address</p>
          <input
            className="text-input"
            value={homeAddress}
            onChange={(e) => setHomeAddress(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Level of Education</p>
          <Select
            defaultValue="Degree"
            style={{
              width: "98%",
            }}
            onChange={(value) => setlevelOfEducation(value)}
            options={[
              {
                value: "Degree Holder",
                label: "Degree Holder",
              },
              {
                value: "Undergraduate",
                label: "Undergraduate",
              },
              {
                value: "Masters",
                label: "Masters",
              },
              {
                value: "Highschool",
                label: "Highschool",
              },
              {
                value: "Primary School",
                label: "Primary School",
              },
            ]}
          />
        </div>
        <div
          className="new-vendor-btn "
          onClick={() => {
            if (firstName === "") {
              displayError("Please enter your first name");
            } else if (secondName === "") {
              displayError("Please enter your Second name");
            } else if (idNo === "") {
              displayError("Please enter your ID Number");
            } else if (birthDate === "") {
              displayError("Please enter your Birthday date");
            } else if (homeAddress === "") {
              displayError("Please enter your Home Address");
            } else if (levelOfEducation === "") {
              displayError("Please enter your level of education");
            } else {
              navigate("/service");
              console.log(
                firstName,
                secondName,
                homeAddress,
                birthDate,
                idNo,
                levelOfEducation
              );
            }
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}
