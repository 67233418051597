import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function EditDocuments() {
  let navigate = useNavigate();
  let location = useLocation();

  const vendor = location.state.vendor;

  const [homeAddress, setHomeAddress] = useState(vendor.homeAddress);
  const [levelOfEducation, setlevelOfEducation] = useState(
    vendor.levelOfEducation
  );

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
      } else {
        navigate("/login");
      }
    });
  }, []);

  return (
    <div className="container-web">
      <div className="h-3">
        <span
          class="material-symbols-outlined m-1 pointer"
          onClick={() =>
            // navigate("/vendor/detail", { state: { vendor: vendor } })
            navigate("/vendor/detail", { state: { vendor: vendor } })
          }
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">
          {" "}
          {location.state.vendor.firstName} {location.state.vendor.secondName}
        </p>
      </div>
      <div className="w-90 m-auto">
        <div className="form-group mt-1">
          <p className="form-label">Documents Details</p>
        </div>
        <div className="form-group mt-1">
          <p className="form-label"> Front Side ID</p>
          <img src={vendor.front} className="w-full" />
        </div>
        <div className="form-group mt-1">
          <p className="form-label"> Back Side ID</p>
          <img src={vendor.back} className="w-full" />
          {vendor.back == "" && <p>No back pic</p>}
        </div>
        {/* <div className="spacer"></div> */}
        <p
          className="text-center text-sm"
          style={{ color: "rgba(0, 0, 0, 0.6)" }}
        >
          {" "}
          Personal details such as ID, name, photo and birth date cannot be
          changed. Contact Support
        </p>

        <div className="new-vendor-btn" onClick={() => {}}>
          Update
        </div>
      </div>
    </div>
  );
}
