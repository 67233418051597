import React from "react";
import { useNavigate } from "react-router-dom";

export default function UploadPicture({
  handleidFrontUpload,
  setPhoto,
  photo,
}) {
  let navigate = useNavigate();
  return (
    <div className="container-web">
      <div className="h-3">
        <span
          class="material-symbols-outlined pointer m-1"
          onClick={() => navigate("/upload")}
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">Upload Picture</p>
      </div>
      <div className="w-90 m-auto">
        {/* <div className="form-group mt-1">
          <p className="form-label">Service Provider Picture</p>
          <div className="form-upload">
            <span class="material-symbols-outlined">photo_camera</span>
          </div>
        </div> */}
        <label class={`format-upload-btn `}>
          <input
            type="file"
            onChange={(event) => {
              setPhoto(event.target.files[0]);
            }}
            accept="/image/*"
          />
          <div className="form-group mt-1">
            <p className="form-label">Display Photo</p>
          </div>

          {photo !== "" ? (
            <div className="form-upload">
              <img
                height={100}
                style={{ borderRadius: "50%" }}
                width={100}
                src={URL.createObjectURL(photo)}
              />
            </div>
          ) : (
            <div className="form-upload">
              <span class="material-symbols-outlined">photo_camera</span>
            </div>
          )}
        </label>

        <div
          className="new-vendor-btn "
          onClick={() => {
            handleidFrontUpload();
          }}
        >
          Review
        </div>
      </div>
    </div>
  );
}
