import { useEffect, useState } from "react";
import dp from "../assets/dp.svg";
import girl from "../assets/girl.svg";
import { collection, getDocs } from "firebase/firestore";

import { useNavigate } from "react-router-dom";
import { auth, db } from "../Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function Home() {
  const navigate = useNavigate();

  const getVendors = async () => {
    setVendors([]);
    const querySnapshot = await getDocs(collection(db, "Vendors"));
    querySnapshot.forEach((doc) => {
      setVendors((prev) => [...prev, doc.data()]);
      // doc.data() is never undefined for query doc snapshots d
      console.log(doc.data());
    });
  };

  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getVendors();
        const uid = user.uid;
      } else {
        navigate("/login");
      }
    });
  }, []);
  return (
    <div className="container-">
      <div className="header container-web">
        <div className="">
          <div className="flex align-center justify-between w-90 m-auto">
            <div className="mt-1">
              <div className="flex">
                <p className="green font-bold font-md">Chap</p>
                <p className="font-bold font-md">Chap</p>
              </div>
            </div>
            <div className="">
              <span class="material-symbols-outlined">chat_bubble</span>
              <img src={dp} className="ml-2" style={{cursor:"pointer"}} onClick={()=>navigate('/profile')}/>
            </div>
          </div>
          <div className="space-2"></div>
          <div className="flex justify-between w-90 m-auto align-center">
            <div>
              <p className="text-md font-bold">{vendors.length}</p>
              <p className="text-xm mt-n1">Vendors Registered</p>
            </div>
            <div
              onClick={() =>
                navigate("/allvendors", { state: { vendors: vendors } })
              }
              className="circlular-floating-btn"
            >
              <span class="material-symbols-outlined">east</span>
            </div>
          </div>
        </div>
        {/* end of header */}

        <div className=" font-bold m-1 ml-2">Recently Added</div>

        {vendors.slice(0, 7).map((vendor) => {
          return (
            <div
              onClick={() => {
                navigate("/vendor/detail", {
                  state: { vendor: vendor },
                });
              }}
              className="mt-1 pointer w-90 m-auto h-4 border align-center pl-1 flex"
            >
              <img src={vendor.pic} className="icon-pic" />
              <div>
                <p className="ml-1 ">
                  {vendor.firstName} {vendor.lastName}
                </p>
                <p className="ml-1 text-grey text-xm">{vendor.serviceType}</p>
              </div>
            </div>
          );
        })}
        {vendors.length == 0 && (
          <div className="no-vendor p-10">No Vendors Registered!</div>
        )}

        {/* end of vendor card */}
        {/* new vendor btn */}
        <div className="new-vendor-btn" onClick={() => navigate("/kyc")}>
          New Vendor
        </div>
      </div>
    </div>
  );
}
