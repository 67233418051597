import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function EditServiceDetails() {
  let navigate = useNavigate();
  let location = useLocation();

  const vendor = location.state.vendor;

  const [serviceType, setServicetype] = useState(vendor.serviceType);
  const [yearsOfExperience, setYearsofExperience] = useState(
    vendor.yearsOfExperience
  );
  const [levelOfEducation, setlevelOfEducation] = useState(
    vendor.levelOfEducation
  );
  const [serviceCategory, setServiceCategory] = useState(
    vendor.serviceCategory
  );
  return (
    <div className="container-web">
      <div className="h-3">
        <span
          class="material-symbols-outlined m-1 pointer"
          onClick={() =>
            // navigate("/vendor/detail", { state: { vendor: vendor } })
            navigate("/vendor/detail", { state: { vendor: vendor } })
          }
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">
          {" "}
          {location.state.vendor.firstName} {location.state.vendor.secondName}
        </p>
      </div>
      <div className="w-90 m-auto">
        <div className="form-group mt-1">
          <p className="form-label">Service Details</p>
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Service Category</p>
          <input
            className="text-input"
            onChange={(e) => setServiceCategory(e.target.value)}
            value={serviceCategory}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Service Type</p>
          <input
            className="text-input"
            onChange={(e) => setServicetype(e.target.value)}
            value={serviceType}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Years of Experience</p>
          <input
            className="text-input"
            onChange={(e) => setYearsofExperience(e.target.value)}
            value={yearsOfExperience}
          />
        </div>
        <div className="form-group mt-1">
          <p className="form-label">Level of Education</p>
          <input
            className="text-input"
            onChange={(e) => setlevelOfEducation(e.target.value)}
            value={levelOfEducation}
          />
        </div>
        {/* <div className="spacer"></div> */}
        <p
          className="text-center text-sm"
          style={{ color: "rgba(0, 0, 0, 0.6)" }}
        >
          {" "}
          Personal details such as ID, name, photo and birth date cannot be
          changed. Contact Support
        </p>

        <div className="new-vendor-btn" onClick={() => {}}>
          Update
        </div>
      </div>
    </div>
  );
}
