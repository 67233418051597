import React from "react";
import { useNavigate } from "react-router-dom";
import girl from "../assets/girl.svg";
import { useLocation } from "react-router-dom";

export default function VendorDetail() {
  let navigate = useNavigate();
  let location = useLocation();
  const vendor = location.state.vendor;

  return (
    <div className="container-web">
      <div className="">
        <span
          class="material-symbols-outlined m-1 pointer"
          onClick={() => navigate("/")}
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">
          {location.state.vendor.firstName} {location.state.vendor.secondName}
        </p>
        <p className="ml-1 mt-n1 ">Active</p>
      </div>
      <div
        onClick={() => {
          navigate("/edit/vendor/details", { state: { vendor: vendor } });
        }}
        className="w-90 m-auto mt-1 h-4 bg-grey pointer align-center pl-1 flex"
      >
        <img src={location.state.vendor.pic} className="icon-pic" />
        <div>
          <p className="ml-1 ">
            {" "}
            {location.state.vendor.firstName} {location.state.vendor.secondName}
          </p>
          <p className="mt-n1 ml-1 text-grey text-xm">
            {" "}
            {location.state.vendor.phone}
          </p>
        </div>
      </div>
      <div
        onClick={() => {
          navigate("/edit/service/details", { state: { vendor: vendor } });
        }}
        className="w-90 m-auto mt-1 h-4 bg-grey pointer align-center pl-1 flex"
      >
        <span class="material-symbols-outlined icon-sm">work</span>
        <div>
          <p className="ml-1 ">Service Details</p>
          <p className="mt-n1 ml-1 text-grey text-xm">Manage</p>
        </div>
      </div>{" "}
      <div
        onClick={() => {
          navigate("/edit/document/details", { state: { vendor: vendor } });
        }}
        className="w-90 m-auto mt-1 h-4 bg-grey pointer align-center pl-1 flex"
      >
        <span class="material-symbols-outlined icon-sm">description</span>
        <div>
          <p className="ml-1 ">Documents</p>
          <p className="mt-n1 ml-1 text-grey text-xm">View and update</p>
        </div>
      </div>
      <div className="flag-acc-btn " onClick={() => {}}>
        Flag Account
      </div>
    </div>
  );
}
