import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Upload({
  idFront,
  setidFront,
  police,
  setPolice,
  handleidFrontUpload,
  setidBack,
  idBack,
}) {
  let navigate = useNavigate();

  const [FrontIdSelected, setFrontIdSelected] = useState(false);
  const [selected, setSelected] = useState("");
  const [imageState, setImageState] = useState('');
  // URL.createObjectURL(idFront);
  return (
    <div className="container-web">
      <div className="h-3">
        <span
          class="material-symbols-outlined pointer m-1"
          onClick={() => navigate("/service")}
        >
          arrow_back
        </span>
        <p className="ml-1 mt-n1 text-lg ">Upload Documents</p>
      </div>
      <div className="w-90 m-auto">
        <label class={`format-upload-btn ${selected}`}>
          <input
            type="file"
            onChange={(event) => {
              setidFront(event.target.files[0]);
            }}
            accept="/image/*"
          />
          <div className="form-group mt-1">
            <p className="form-label">ID Front</p>

            {idFront !== "" ? (
              <div className="form-upload">
                <img
                  height={100}
                  width={700}
                  src={URL.createObjectURL(idFront)}
                />
              </div>
            ) : (
              <div className="form-upload">
                <span class="material-symbols-outlined">photo_camera</span>
              </div>
            )}
          </div>
          {idFront.name}
        </label>
        <label class={`format-upload-btn ${selected}`}>
          <input
            type="file"
            onChange={(event) => {
              setidBack(event.target.files[0]);
            }}
            accept="/image/*"
          />
          <div className="form-group mt-1">
            <p className="form-label">ID Back</p>

            {idBack !== "" ? (
              <div className="form-upload">
                <img
                  height={100}
                  width={700}
                  src={URL.createObjectURL(idBack)}
                />
              </div>
            ) : (
              <div className="form-upload">
                <span class="material-symbols-outlined">photo_camera</span>
              </div>
            )}
          </div>
          {idBack.name}
        </label>
        <label class={`format-upload-btn ${selected}`}>
          <div className="form-group mt-1">
            <p className="form-label">Police Clearance Certificate</p>

            <input
              type="file"
              onChange={(event) => {
                setPolice(event.target.files[0]);
              }}
              accept="/image/*"
            />
            <div className="form-group mt-1">
            

              {police !== "" ? (
                <div className="form-upload">
                  <img
                    height={100}
                    width={700}
                    src={URL.createObjectURL(police)}
                  />
                </div>
              ) : (
                <div className="form-upload">
                  <span class="material-symbols-outlined">photo_camera</span>
                </div>
              )}
            </div>
            {police.name}
          </div>
        </label>

        <div
          className="new-vendor-btn "
          onClick={() => {
                   navigate("/uploadpic");
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}
